/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
@font-face {
  font-family: "Gotham Rounded Book";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/SVN-Gotham Book.otf") format("opentype");
  /* SVN-Gotham Book */
  /* src: url("/fonts/SVN-Gotham Book.otf") format("otf"); */
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html,
body {
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

/* -------------------------------------------------------------------------- */

*,
html,
body {
  /* font-family: "Roboto" !important; */
  font-family: "Gotham Rounded Book" !important;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}
::-moz-selection {
  /* Code for Firefox */
  background: "#daebe4";
  color: #000;
}

::selection {
  background: #daebe4;
  color: #000;
}

@media (prefers-color-scheme: dark) {
}

.activeOpacity {
  display: block;
  opacity: 1;
}
.activeSubMenu {
  transform: translate(0, 99%);
}

.grecaptcha-badge {
  visibility: hidden;
}

.reveal {
  position: relative;
  opacity: 0;
  transform: translateY(150px);
}

.reveal.active {
  transition: 1s all ease;
  transform: translateY(0);
  opacity: 1;
}

.activeOpacity {
  opacity: 1;
}

/* -------------------------------------------------------------------------- */
/*                                   slider                                   */
/* -------------------------------------------------------------------------- */
